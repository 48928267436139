<template>
  <main class="login">
    <div v-if="isConfirm">
      <forgetpassword @clicked="loginScreen" :loginName="username"></forgetpassword>
    </div>

    <div class="d-flex" v-else>
      <LoginLeft></LoginLeft>
      <div class="d-flex justify-content-center w-100 px-5">
        <div class="login-right-section">
          <header class="login-header pt-0">
            <nav class="d-flex align-items-center p-relative">
              <ul class="p-0 nav nav-tabs login-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="signin-tab" data-bs-toggle="tab" data-bs-target="#signin"
                    type="button" role="tab" aria-controls="signin" aria-selected="true">{{ $t("Sign in")
                    }}</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="register-tab" data-bs-toggle="tab" data-bs-target="#register" type="button"
                    role="tab" aria-controls="register" aria-selected="false">{{ $t("Register")
                    }}</button>
                </li>
              </ul>
              <a class="pointer login-header__logo" href="#">
                <img :src="currentLogo" alt />
              </a>
              <div class="d-flex">
                <v-btn
                  class="ml-2"
                  min-width="0"
                  text
                  @click="openSupportModal"
                >
                  <img src="/assets/img/user-headset-dark.svg" alt="" @click="openSupportModal" />
                </v-btn>
                <ChangeLangSelect :isLoginPage="true"></ChangeLangSelect>
              </div>
            </nav>
          </header>

          <div class="login__enter mt-18">
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="signin" role="tabpanel" aria-labelledby="signin-tab">
                <ResellerLogin></ResellerLogin>
              </div>
              <div class="tab-pane fade" id="register" role="tabpanel" aria-labelledby="register-tab">
                <ResellerRegister />
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading :active.sync="loggingIn" :can-cancel="false" :is-full-page="true"></loading>
    </div>
    <SupportPopup></SupportPopup>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import forgetpassword from './ForgetPassword';
import SupportPopup from '@/components/SupportPopup.vue';
import LoginLeft from "../components/auth/LoginLeft.vue";
import ResellerRegister from "../components/auth/ResellerRegister"
import ResellerLogin from "../components/auth/ResellerLogin.vue";
import ChangeLangSelect from '@/components/ChangeLangSelect'

export default {
  name: 'Reseller_Login',
  components: {
    Loading,
    forgetpassword,
    SupportPopup,
    ResellerRegister,
    LoginLeft,
    ResellerLogin,
    ChangeLangSelect
  },
  computed: {
    ...mapState({
      loggingIn: (state) => state.account.loggingIn,
    }),
  },

  data: () => {
    return {
      username: '',
      password: '',
      isConfirm: false,
    };
  },
  created() {
    this.logout();
  },
  methods: {
    ...mapActions('account', ['login', 'logout']),

    loginScreen(change) {
      this.isConfirm = change;
    },
    openSupportModal() {
      this.$modal.show('supportPopup');
    },
  },
};
</script>
