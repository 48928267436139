<template>
    <div>
      <h3 class="auth_title text-center w-100">{{ $t("Reseller Sign into Pay Analog") }}</h3>
      <p class="text login__text text-center w-100">{{ $t("Grow your business profit by direct or affliate sales") }}</p>
      <form @submit.prevent="handleSubmit" class="authorisation login__authorisation">
          <div class="authorisation__name">
              <label class="label" for="username">{{ $t("Username") }}</label>
              <div class="input-group">
                  <input id="username" v-model="username" v-model.lazy="$v.username.$model"
                      class="form-control input authorisation__input" :class="{ ' validation-error': $v.username.$error }"
                      type="text" @keyup.enter="onEnter('uname')" />
                  <span class="input-group-text"><i class="far fa-envelope"></i></span>
              </div>
          </div>

          <div class="authorisation__password">
              <label class="label" for="password">{{ $t("Password") }}</label>
              <div class="input-group">
                  <input id="password" v-model="password" v-model.lazy="$v.password.$model"
                      class="form-control input authorisation__input" :class="{ ' validation-error': $v.password.$error }"
                      type="password" @keyup.enter="onEnter('upass')" />
                  <span class="input-group-text" style="Font Awesome 6 Pro">
                      <img src="/assets/img/lock.png">
                  </span>
              </div>
          </div>

          <div class="authorisation__remember">
              <a class="pointer authorisation__forgot" @click="changeConfirm">
                  {{ $t("Forgot my Password?") }}
              </a>
          </div>

          <a class="pointer authorisation__button color-white mb-3 mt-15" @click="handleSubmit">
              {{ $t("Sign in") }}
          </a>
          <div class="login-footer d-flex align-items-center justify-content-between">
            <router-link
              :to="{ name: 'User_Login' }"
            >
              <a class="back-link">{{ $t("Back to user login") }}</a>
            </router-link>
            <div class="login-contact-block df-c fs14 pointer" @click="openSupportModal">
                <div class="contact-btn df-c mx10">
                    <img src="/assets/img/user-headset-light.svg" alt="" />
                </div>
                <span> {{ $t("Support") }}</span>
            </div>
          </div>
      </form>
      <Modal2FA ref="modal2FARef" userType="Admin"></Modal2FA>
      <loading :active.sync="loggingIn" :can-cancel="false" :is-full-page="true"></loading>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, email, sameAsData } from 'vuelidate/lib/validators';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import DeviceDetector from "device-detector-js";

import i18nService from '@/common/i18n.service.js';
import Modal2FA from "@/components/auth/Modal2FA.vue";

export default {
  mixins: [validationMixin],
  name: 'Reseller_Login',
  components: {
    Loading,
    Modal2FA
  },
  computed: {
    ...mapState({
      loggingIn: state => state.account.loggingIn,
      loggedIn: state => state.account.loggedIn
    }),
  },

  data: () => {
    return {
      username: '',
      password: '',
      isConfirm: false,
    };
  },
  validations: {
    username: {
      required,
    },
    password: {
      required,
    },
  },
  methods: {
    ...mapActions('account', ['login', 'logout']),

    async handleSubmit() {
      let that = this;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const deviceDetector = new DeviceDetector();
      const userAgent = window.navigator.userAgent;
      const device = deviceDetector.parse(userAgent); 
      const authData = {
        username: this.username,
        password: this.password,
        browser: device.client.name,
        os_name: device.os.name,
        os_version: device.os.version,
        os_platform: device.os.platform,
        device_type: device.device.type
      }

      this.login(authData).then(
        async result => {
          if (that.loggedIn) {
            localStorage.setItem("user_type_logged_in", "Reseller");
            try {
              await that.$router.push(that.$route.query.redirect || "/");
            } catch (e) {}
          } else if (result.message == "Check 2fa") {
            that.openModal2FA();
          }
          return result;
        },
        error => {
          that.password = "";
          return error;
        }
      );
    },
    changeConfirm() {
      this.isConfirm = true;
    },
    onEnter(formType) {
      if (formType == 'uname' && this.password == '') {
        document.getElementById('password').focus();
      } else if (formType == 'upass' && this.username == '') {
        document.getElementById('username').focus();
      }

      if (this.username != '' && this.password != '') {
        this.handleSubmit();
      }
    },
    openSupportModal() {
      this.$modal.show('supportPopup');
    },
    openModal2FA() {
      this.$refs.modal2FARef.openModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.authorisation {
    text-align: unset;
}
 .login-footer {
    margin-top: 20px;
    .login-contact-block {
      display: none;
    }
  }
  @media screen and (max-width: 992px) {
    .login-footer {
      margin-top: 50px;
      .login-contact-block {
        display: flex;
        margin-right: 0;
      }
    }
  }
</style>