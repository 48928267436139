<template>
  <div>
    <h3 class="auth_title text-center w-100">{{ $t("Reseller Register to Pay Analog") }}</h3>
    <p class="text login__text text-center w-100">{{ $t("Grow your business profit by direct or affliate sales") }}</p>
    <section class="registration_form">
      <div class="reseller-info register-reseller__reseller-info">
        <div class="reseller-info__container">
          <div class="reseller-info__wrapper">
            <div class="reseller-info__company reseller-info__case validation-form-group"
              :class="{ 'form-group--error': $v.newUser_data.name.$error }">
              <label class="label reseller-info__label">
                * {{ $t('Business name') }}
              </label>
              <input class="input" type="text" v-model="newUser_data.name" @input="$v.newUser_data.name.$touch" />
              <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newUser_data.name.required">
                {{ $t('This is a required field.') }}
              </div>
            </div>
            <div class="reseller-info__phone-contact reseller-info__case validation-form-group" :class="{
              'form-group--error': $v.newUser_data.business_Reg_no.$error,
            }">
              <label class="label reseller-info__label">
                * {{ $t('Business registration number') }}
              </label>
              <input class="input" type="tel" v-model="newUser_data.business_Reg_no" />
              <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newUser_data.business_Reg_no.required">
                {{ $t('This is a required field.') }}
              </div>
            </div>
            <div class="reseller-info__phone-reseller reseller-info__case validation-form-group">
              <label class="label reseller-info__label">
                {{ $t('Business contact name') }}
              </label>
              <input class="input" type="text" v-model="newUser_data.business_contact" @keypress="isNumber($event)" />
            </div>
          </div>
  
          <div class="reseller-info__wrapper">
            <div class="reseller-info__adress reseller-info__case validation-form-group"
              :class="{ 'form-group--error': $v.newUser_data.city.$error }">
              <label class="label reseller-info__label">* {{ $t('Address') }}</label>
              <vue-google-autocomplete id="map3" classname="input" placeholder=""
                v-on:placechanged="getAddressDataReseller" :country="['il']" v-model="googleAddressInput"
                @input="$v.newUser_data.city.$touch">
              </vue-google-autocomplete>
              <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newUser_data.city.required">
                {{ $t('This is a required field.') }}
              </div>
            </div>
            <div class="reseller-info__phone-reseller reseller-info__case validation-form-group">
              <label class="label reseller-info__label">
                {{ $t('Business phone number') }}
              </label>
              <input class="input" type="text" v-model="newUser_data.phone_number" @keypress="isNumber($event)" />
            </div>
            <div class="reseller-info__phone-reseller reseller-info__case validation-form-group" :class="{
              'form-group--error':
                $v.newUser_data.business_contact_mobile.$error,
            }">
              <label class="label reseller-info__label">
                {{ $t('Business contact mobile number') }}
              </label>
              <input class="input" type="text" v-model="newUser_data.business_contact_mobile"
                @input="$v.newUser_data.business_contact_mobile.$touch" @keypress="isNumber($event)" />
              <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newUser_data.business_contact_mobile.required">
                {{ $t('This is a required field.') }}
              </div>
            </div>

  
            <!-- <div class="reseller-info__email reseller-info__case validation-form-group" :class="{
              'form-group--error':
                $v.newUser_data.business_account_email.$error,
            }">
              <label class="label reseller-info__label">
                * {{ $t('E-mail') }}
              </label>
              <input class="input" type="email" placeholder="email@example.com"
                v-model="newUser_data.business_account_email" @input="$v.newUser_data.business_account_email.$touch" />
              <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newUser_data.business_account_email.required || !$v.newUser_data.business_account_email.email">
                {{ $t('Invalid value') }}
              </div>
            </div>
            <div class="reseller-info__email reseller-info__case validation-form-group" :class="{
              'form-group--error': $v.newUser_data.account_mail.$error,
            }">
              <label class="label reseller-info__label">
                {{ $t('Accounting Email') }}
              </label>
              <input class="input" type="email" placeholder="email@example.com" v-model="newUser_data.account_mail"
                @input="$v.newUser_data.account_mail.$touch" />
              <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newUser_data.account_mail">
                {{ $t('Invalid value') }}
              </div>
            </div>
  
            <div class="register-opening-week">
              <div class="d-flex align-items-center justify-content-between mb-7">
                <label class="label reseller-info__label">
                  {{ $t('Opening hours') }}
                </label>
                <label class="label reseller-info__checkbox-label" for="everyday"> <input class="reseller-checkobox"
                    type="checkbox" id="everyday" /> 24X7</label>
              </div>
              <div class="reseller-info__work-wrapper">
                <div class="reseller-info__work-days">
                  <div class="reseller-info__sunday">
                    <label class="label reseller-info__sunday-label">
                      {{ $t('Mon') }} - {{ $t('Fri') }}
                    </label>
                    <div class="reseller-info__sunday-wrapper">
                      <input class="input reseller-info__input" type="text" />
                      <span class="reseller-info__dash">-</span>
                      <input class="input reseller-info__input" type="text" />
                    </div>
                  </div>
  
                  <div class="reseller-info__friday">
                    <label class="label reseller-info__friday-label">
                      {{ $t('Sat') }} - {{ $t('Sun') }}
                    </label>
                    <div class="reseller-info__friday-wrapper">
                      <input class="input reseller-info__input" type="text" />
                      <span class="reseller-info__dash">-</span>
                      <input class="input reseller-info__input" type="text" />
                    </div>
                  </div>
  
                  <div class="reseller-info__saturday">
                    <label class="label reseller-info__saturday-label" for>
                      {{ $t('Holidays') }}
                    </label>
                    <div class="reseller-info__saturday-wrapper">
                      <input class="input reseller-info__input" type="text" />
                      <span class="reseller-info__dash">-</span>
                      <input class="input reseller-info__input" type="text" />
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
  
        <hr class="block-hr">

        <div class="reseller-info__container">
          <div class="reseller-info__wrapper">
            <div class="reseller-info__email reseller-info__case validation-form-group" :class="{
              'form-group--error': $v.newUser_data.first_name.$error,
            }">
              <label class="label reseller-info__label">
                * {{ $t('Business owner First name') }}
              </label>
              <input class="input" type="email" placeholder="First Name" v-model="newUser_data.first_name"
                @input="$v.newUser_data.first_name.$touch" />
              <div class="color-red1 fs12 valide-error-msg" v-if="$v.newUser_data.first_name.$error">
                {{ $t('Invalid value') }}
              </div>
            </div>
  
            <div class="reseller-info__email reseller-info__case validation-form-group" :class="{
              'form-group--error': $v.newUser_data.last_name.$error,
            }">
              <label class="label reseller-info__label">
                * {{ $t('Business owner Last name') }}
              </label>
              <input class="input" type="text" placeholder="Last Name" v-model="newUser_data.last_name"
                @input="$v.newUser_data.last_name.$touch" />
              <div class="color-red1 fs12 valide-error-msg" v-if="$v.newUser_data.last_name.$error">
                {{ $t('Invalid value') }}
              </div>
            </div>
  
            <div class="reseller-info__email reseller-info__case validation-form-group" :class="{
              'form-group--error': $v.newUser_data.password.$error,
            }">
              <label class="label reseller-info__label">
                * {{ $t('Password') }}
              </label>
              <input class="input" type="email" placeholder="Password" v-model="newUser_data.password"
                @input="$v.newUser_data.password.$touch" />
              <div class="color-red1 fs12 valide-error-msg" v-if="$v.newUser_data.password.$error">
                {{ $t('Invalid value') }}
              </div>
            </div>
          </div>
          <div class="reseller-info__wrapper">
            <div class="reseller-info__email reseller-info__case validation-form-group" :class="{
              'form-group--error': $v.newUser_data.owner_phone_number.$error,
            }">
              <label class="label reseller-info__label">
                * {{ $t('Business Owner Phone Number') }}
              </label>
              <input class="input" type="text" placeholder="Phone Number" v-model="newUser_data.owner_phone_number"
                @input="$v.newUser_data.owner_phone_number.$touch" />
              <div class="color-red1 fs12 valide-error-msg" v-if="$v.newUser_data.owner_phone_number.$error">
                {{ $t('Invalid value') }}
              </div>
            </div>
            <div class="reseller-info__email reseller-info__case validation-form-group" :class="{
              'form-group--error': $v.newUser_data.username.$error,
            }">
              <label class="label reseller-info__label">
                * {{ $t('User Name (Business Owner UserName)') }}
              </label>
              <input class="input" type="email" placeholder="Email" v-model="newUser_data.username"
                @input="$v.newUser_data.username.$touch" />
              <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newUser_data.username.email">
                {{ $t('Invalid value') }}
              </div>
            </div>
          </div>
        </div>

        <div class="register-footer reseller-info__container mt-5 mb-10">
          <div class="d-flex">
            <button class="pointer authorisation__button color-white" @click="save()">
              {{ $t('Save') }}
            </button>
            <button class="pointer authorisation__button red__button color-white mx-10" @click="resetForm()">
              {{ $t('Cancel') }}
            </button>
          </div>
          <router-link
            :to="{ name: 'User_Login' }"
          >
            <a class="back-link">{{ $t("Back to user login") }}</a>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: 'ResellerRegister',
  mixins: [validationMixin],
  components: {
  },
  validations: {
    newUser_data: {
      name: {
        required,
      },
      business_Reg_no: {
        required,
      },
      city: {
        required,
      },
      business_account_email: {
        required,
        email
      },

      first_name: {
        required,
      },
      last_name: {
        required,
      },
      password: {
        required,
      },
      username: {
        required,
      },
      owner_phone_number: {
        required,
      },
      account_mail: {
        // required,
      },
      business_contact_mobile: {
        // required,
      },
    },
  },
  data: () => {
    return {
      newUser_data: {
        first_name: '',
        last_name: '',
        phone_number: '',
        // account_mail: '',
        password: '',
        city: '',
        street: '',
        country: '',
        // business_account_email: '',
        business_contact: '',
        username: '',
        business_contact_mobile: '',
        name: '',
        owner_phone_number: '',
        business_Reg_no: '',
      },
      googleAddressInput: '',
    };
  },
  computed: {
  },
  methods: {
    ...mapActions('shaniv_management', {
      resellerRegister: 'resellerRegister',
      createResellerUser: 'createResellerUser',
    }),

    async save() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }

      let newUser = this.newUser_data;
      newUser.role_id = 3;
      this.resellerRegister(newUser).then(async (res) => {
        if (res.message == 'success') {
          newUser.business_id = res.id;
          await this.createResellerUser(newUser);
          // obj.$router.push(obj.$route.query.redirect || '/login');
          const id = res.id;
          await this.updateBusinessData({
            id: id,
          });

          this.$emit('setNewseller', res.id);
        }
      });

      this.resetForm();
    },

    resetForm() {
      this.newUser_data = {
        first_name: '',
        last_name: '',
        phone_number: '',
        account_mail: '',
        password: '',
        city: '',
        street: '',
        country: '',
        business_account_email: '',
        business_contact: '',
        username: '',
        business_contact_mobile: '',
        name: '',
        owner_phone_number: '',
        business_Reg_no: '',
      };
    },
    getAddressDataReseller(address) {
      this.newUser_data.city = address.locality;
      this.newUser_data.street = address.route;
    },
  },
};
</script>

<style lang="scss" scoped>
.register-opening-week {
  padding: 12px 24px 12px 6px;
  background: #F6F6F6;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #70707024;
  .reseller-info__work-wrapper {
    padding-left: 20px;
    .reseller-info__work-days {
      margin-bottom: 0;
    }
  }
}

.register-footer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  .authorisation__button {
    width: 130px;
  }
  @media screen and (max-width: 720px) {
    flex-direction: column;
    .back-link {
      margin-top: 20px;
    }
  }
}
</style>
